<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <ApeMenu :menus="menus" />
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'BusinessDataIndex',
  components: {
    PageHeaderLayout,
  },
  data() {
    return {
      menus: [
        { icon: 'zhibo', display_name: '直播(达播)数据', url: '/business/live_data', permission_name: 'live_data_list' },
        { icon: 'dianpu', display_name: '直播(日播)数据', url: '/business/live_data_delegate', permission_name: 'live_data_list' },
        // {
        //   icon: 'dianpu',
        //   display_name: '店铺数据（汇总）',
        //   url: '/business/shop_data',
        //   permission_name: 'shop_data_list',
        // },
        { icon: 'huizong', display_name: '每日汇总', url: '/business/day_summary', permission_name: 'bs_day_summary' },
        { icon: 'yuehuizong', display_name: '月度汇总', url: '/business/month_summary', permission_name: 'bs_month_summary' },
        // { icon: 'config', display_name: '设置', url: '/business/live_setting_department', permission_name: 'live_group_list' },
      ],
    }
  },
}
</script>

<style scoped></style>
